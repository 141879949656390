@import "bootstrap/scss/mixins/banner";

@include bsBanner("");
// Configuration
@import "bootstrap/scss/functions";


$primary: #E12454;
$secondary: #210056;
$dark: #333333;
$light: #F3FCFF;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "dark": $dark,
        "light": $light,
);

$body-color: $dark;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 6,
);

$font-family-sans-serif: 'Futura PT';
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.125;
//$h1-font-size: $font-size-base * 2.5;
//$h2-font-size: $font-size-base * 2;
//$h3-font-size: $font-size-base * 1.75;
//$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.125;
//$h6-font-size: $font-size-base;

$btn-font-weight: 700;

//$navbar-brand-margin-end: 3rem;
$navbar-light-color: $dark;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($dark, .3);
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($dark, .1);
//$navbar-light-brand-color:          $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:    $navbar-light-active-color !default;


$fancybox-accent-color: var(--fancybox-accent-color, rgba($primary, 0.96));
$fancybox-focus-shadow: 0 0 0 1px #fff, 0 0 0 2px var(--fancybox-accent-color, rgba($primary, 0.94)) !default;

@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";