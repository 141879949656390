@import "fonts";
@import "variables";

@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

@import "@fancyapps/ui/src/Fancybox/Fancybox.scss";

@import "animate.css/animate.compat.css";
.wow {
  visibility: hidden;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: currentColor;
}

header {
  transition-property: background-color, backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }

  .navbar-brand {
    img {
      max-height: 5rem;
    }
  }
}

.welcome {
  background-size: cover;
  background-position: center;
  margin-top: calc(var(--header-height, 0) * -1);
  padding-top: calc(var(--header-height, 0) + 1.5rem);
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    padding-top: calc(var(--header-height, 0) + 6rem);
    padding-bottom: 6rem;
  }
}